export default function BlockPage() {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        background: '#211c32',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <h1
        style={{
          color: '#f52727',
          fontWeight: 700,
          padding : 10
        }}
      >
        You have been blocked :(
      </h1>
      <h3
        style={{
          color: '#c4c4c4',
          padding : 10
        }}
      >
        If you think this action is happened by mistake
        <br />
        you can message our team to consider options
      </h3>
    </div>
  )
}
