import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { NetworkContextName } from '../constants'
import { ChainId } from '@sushiswap/sdk'
import { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { formatBalance } from 'utils'

export function useActiveWeb3React(): Web3ReactContextInterface<Web3Provider> & {
  chainId?: ChainId
} {
  const context = useWeb3ReactCore<Web3Provider>()
  const contextNetwork = useWeb3ReactCore<Web3Provider>(NetworkContextName)
  return context.active ? context : contextNetwork
}

export function useBalance() {
  const { account, active } = useActiveWeb3React()
  const [balance, setBalance] = useState<any>(0)
  const [balanceNumber, setBalanceNumber] = useState<any>(0)
  useEffect(() => {
    if (active) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        provider.getBalance(account).then((res) => {
          setBalanceNumber(res)
        })
      } catch (error) {
        console.error('UseBalance:', error)
      }
    }
  }, [active, account])

  useEffect(() => {
    if (balanceNumber) {
      setBalance(formatBalance(balanceNumber.toString()))
    }
  }, [balanceNumber])

  return { balance }
}
