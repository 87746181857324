// @ts-nocheck
import { FC, useEffect, lazy, Suspense, useState } from 'react'
import Header from './components/common/header/Header'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom'
import Footer from './components/common/Footer'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import {
  setCurrentBlockTimestamp,
  setKnightPrice,
} from './state/application/actions'
import Spinner from 'components/common/Spinner'
import { useAddress } from '@thirdweb-dev/react'
import InstallPopUp from 'components/common/InstallPopUp'
import { getOperatingSystem } from 'utils/getOS'

// Lazy load components
const Profile = lazy(() => import('./pages/Profile'))
const Staking = lazy(() => import('./pages/Staking'))
const Merge = lazy(() => import('./pages/Merge'))
const VoteDetails = lazy(
  () => import('./components/Staking/voteDetailPage/VoteDetail'),
)
const Quest = lazy(() => import('pages/Quest'))
const SteampunkChestProfile = lazy(() => import('pages/ChestProfile'))
const ClockworkChestProfile = lazy(() => import('pages/ClockWorkChestProfile'))
const SpecialMerge = lazy(() => import('pages/SpecialMerge'))
const ProfileV2 = lazy(() => import('pages/ProfileV2'))
const TransactNftV2 = lazy(() => import('pages/TransactNFTV2'))
const ProfileLooUpV2 = lazy(() => import('pages/ProfileLookUpV2'))
const MarketplaceV2 = lazy(() => import('pages/MarketplaceV2'))

export const Loading = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100vw',
      background: '#211c32',
      position: 'fixed',
      zIndex: 89,
    }}
  >
    <Spinner />
  </div>
)

const Routes: FC = () => {
  const dispatch = useDispatch()
  const address = useAddress()
  const location = useLocation()
  const lastVisit = localStorage.getItem('USER_LAST_VISIT')
  const installRejection = localStorage.getItem('INSTALLATION_REJECTED')
  const OS = getOperatingSystem()
  const isInstalled =
    window.navigator.standalone === true ||
    document.referrer.includes('android-app://') ||
    window.matchMedia('(display-mode: standalone)').matches ||
    window.matchMedia('(display-mode: minimal-ui)').matches ||
    window.matchMedia('(display-mode: fullscreen)').matches ||
    window.navigator.userAgent.includes('wv') || // Check for WebView (Android)
    window.navigator.userAgent.includes('CriOS') // Check for Chrome on iOS
  const [showPopUp, setShowPopUp] = useState(false)

  useEffect(() => {
    let getCBTInterval
    const getUsdPrice = async () => {
      try {
        const data = await axios.get(
          'https://api.coingecko.com/api/v3/coins/forest-knight',
        )

        const percentage_change =
          data.data.market_data.price_change_percentage_24h

        dispatch(
          setKnightPrice({
            price: data.data.market_data.current_price.usd,
            percentage_change: percentage_change,
          }),
        )
      } catch (e) {
        console.error('getUsdPrice', e)
      }
    }
    getUsdPrice()

    getCBTInterval = setInterval(() => {
      dispatch(setCurrentBlockTimestamp(Date.now()))
    }, 10000)

    return () => {
      clearInterval(getCBTInterval)
    }
  }, [address, dispatch])

  useEffect(() => {
    if (!isInstalled && OS !== 'Desktop') {
      const timeNow = new Date().getTime()
      if (!lastVisit) {
        localStorage.setItem('USER_LAST_VISIT', timeNow)
        setShowPopUp(true)
      } else {
        let timeDif = Math.abs(
          Math.round((timeNow - Number(lastVisit)) / (1000 * 60 * 60)),
        )
        if (installRejection) {
          if (timeDif >= 5) {
            localStorage.removeItem('INSTALLATION_REJECTED')
            localStorage.setItem('USER_LAST_VISIT', timeNow)
            setShowPopUp(true)
          }
        } else {
          localStorage.setItem('USER_LAST_VISIT', timeNow)
          setShowPopUp(true)
        }
      }
    }
  }, [])

  const closePopUp = () => {
    setShowPopUp(false)
    localStorage.setItem('INSTALLATION_REJECTED', true)
  }

  return (
    <Router>
        <Suspense fallback={<Loading />}>
          <Header />
          {showPopUp && <InstallPopUp os={OS} close={closePopUp} />}
          <Switch>
            <Route path="/" exact component={MarketplaceV2} />
            <Route path="/profile-v1" exact component={Profile} />
            <Route path="/profile-v2" exact component={ProfileV2} />
            <Route path="/explore" exact component={TransactNftV2} />
            <Route path="/merge" exact component={Merge} />
            <Route path="/forge" exact component={SpecialMerge} />
            <Route path="/dao" exact component={Staking} />
            <Route exact path={`/dao/proposal/:id`} component={VoteDetails} />
            <Route path="/account" exact component={ProfileLooUpV2} />
            <Route
              path="/chest-steampunk"
              exact
              component={SteampunkChestProfile}
            />
            <Route
              path="/chest-clockwork"
              exact
              component={ClockworkChestProfile}
            />
            <Route path="/quest" exact component={Quest} />
          </Switch>
        </Suspense>
        <Footer />
    </Router>
  )
}

export default Routes
