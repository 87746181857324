import { createSlice } from '@reduxjs/toolkit'

/**
 * @description Storing the redux store config for the marketplace
 */

export const marketplaceSlice = createSlice({
  name: 'accountDetailsState',
  initialState: {
    gridview: true,
    blockList: ['0xDCD363a8187eA882a65f5c235a2339EaF633CA3B'],
  },
  reducers: {
    toggleGridView: (state, { payload }) => {
      if (typeof payload == 'boolean') {
        state.gridview = payload
      }
      state.gridview = !state.gridview
    },
  },
})

export const { toggleGridView } = marketplaceSlice.actions

export default marketplaceSlice.reducer
